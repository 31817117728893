
export const api_url = {
	api_path: {
		post_login: '/memberProcess/postLogin'
		, get_main_data: '/main/getMainInfo'
		, post_merchant_withdrawal: '/main/postMerchantWithdrawal'
		, get_merchant_history: '/main/getMerchantHistory'
		// PIN 설정
		, post_pin: 'auth/postPin'
		// PIN 변경
		, put_pin: 'auth/postPin'
		// PIN 확인
		, post_pin_check: 'management/postPinCheck'
		// 가맹점 등록 조회 - 엑셀
		, post_franchisee_excel: 'member/postFranchiseeExcel'
		// 가맹점 등록
		, post_franchisee_account: 'main/postFranchiseeAccount'
		// 가맹점 정산 조회 - 엑셀
		, post_calculate_excel: 'member/postCalculateExcel'
		// 가맹점 정산 등록
		, post_settlement: 'member/postSettlement'
	}
}