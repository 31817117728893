<template>
	<div
		class="position-fixed-full z-index-layer"
	>
		<div class="bg-layer z-index-bg"></div>
		<div
			v-if="is_full"
			class="position-relative flex-column justify-center full-width full-height z-index-contents"
			style="min-width: 480px; max-width: 1200px; margin: 0 auto"
		>
			<slot name="body"></slot>
		</div>
		<div
			v-else
			class="position-relative pa-10 flex-column justify-center full-width full-height z-index-contents"
			style="min-width: 480px; max-width: 1200px; margin: 0 auto"
		>
			<slot name="body"></slot>
		</div>

	</div>
</template>

<script>
export default {
	name: 'PopupLayer'
	, props: ['is_full']
}
</script>

<style>
.position-fixed-full {
	position: fixed; left: 0; top: 0; width: 100%; height: 100%;
}
.z-index-layer {
	z-index: 9999;
}
.z-index-contents {
	z-index: 9998;
}
.z-index-bg {
	z-index: 9997;
}

.bg-layer {
	position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0.9; background-color: black;
}
</style>