
import axios from 'axios'
export const common = {

	isMobile: function(){
		let user = navigator.userAgent;
		let w_type = false
		if(user.toLowerCase().indexOf('iphone') > -1 || user.toLowerCase().indexOf('ipad') > -1 || user.toLowerCase().indexOf('ipod') > -1 || user.toLowerCase().indexOf('android') > -1){
			w_type = true
		}

		// console.log('isMobile', w_type)
		return w_type
	}
	, getIp: async function(){
		try {
			const response = await axios.get('https://api.ipify.org?format=json');
			if(response){
				return response.data.ip
			}else{
				throw 'error'
			}
		} catch (error) {
			console.error(error);
		}
	}
	, convertToKoreanNumber: function(val) {
		var numKor = new Array("", "일", "이", "삼", "사","오","육","칠","팔","구","십");                                  // 숫자 문자
		var danKor = new Array("", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천");    // 만위 문자열
		var result = "";

		if(val && !isNaN(val)){
			// CASE: 금액이 공란/NULL/문자가 포함된 경우가 아닌 경우에만 처리

			for(var i = 0; i < val.length; i++) {
				var str = "";
				var num = numKor[val.charAt(val.length - (i+1))];
				if(num != "")   str += num + danKor[i];    // 숫자가 0인 경우 텍스트를 표현하지 않음
				switch(i){
					case 4:str += "만";break;     // 4자리인 경우 '만'을 붙여줌 ex) 10000 -> 일만
					case 8:str += "억";break;     // 8자리인 경우 '억'을 붙여줌 ex) 100000000 -> 일억
					case 12:str += "조";break;    // 12자리인 경우 '조'를 붙여줌 ex) 1000000000000 -> 일조
				}

				result = str + result;
			}

			// Step. 불필요 단위 제거
			if(result.indexOf("억만") > 0)    result = result.replace("억만", "억");
			if(result.indexOf("조만") > 0)    result = result.replace("조만", "조");
			if(result.indexOf("조억") > 0)    result = result.replace("조억", "조");
		}

		return result ;
	}

	, geKoreanNumber: function(number) {
		const koreanUnits = ['조', '억', '만', ''];
		const unit = 10000;
		let answer = '';

		while (number > 0) {
			const mod = number % unit;
			const modToString = mod.toString().replace(/(\d)(\d{3})/, '$1,$2');
			number = Math.floor(number / unit);
			answer = `${modToString}${koreanUnits.pop()}${answer}`;
		}
		return answer;
	}
	, getPowNumber: function(number, line){
		console.log(number, line)
		let d = 0
		if(Number(number)){
			d = Math.floor(number / Math.pow(10,line-1))%10
		}
		return d
	}
}